<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">供应链金融</p>
        <p class="header-desc">
          供应链金融是利用链条中核心企业的信用度来传导及带动上下游信用资质相对较低的企业去进行融资，通过使用互联网、大数据、区块链等技术，能够有效解除信用传导过程中的痛点，释放核心企业信用到整个供应链条的多级供应商，降低业务成本，提高整个供应链上的资金运转效率。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          通过搭建众享区块链供应链金融平台，为企业、金融机构建立区块链联盟。<br />众享区块链供应链金融平台围绕核心企业，覆盖其上下游中小微企业、商业银行、保理公司等资金端及物流、仓储企业。平台核心功能为整合贸易基础信息的聚合，确保账款信息来源的真实性、平台操作的信息不可篡改、操作的过程信息透明公开、操作成功的信息可溯源查询。平台聚焦应收账款登记、确权及应收账款转让后的管理，通过撮合应收账款提前支付、应收账款的保理融资等方式帮助供应链上的供应商更快更稳健地获得资金支持。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">方案优势</div>
      <div class="advantage-content">
        <el-row :gutter="20">
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/supply-chain/advantage-1.png"
              />
              <div class="title">多方确权认证</div>
              <div class="text">
                通过区块链技术解决信息孤岛问题，多个利益相关方可以提前设定好规则，实现数据的互通和信息的共享。
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/supply-chain/advantage-2.png"
              />
              <div class="title">信息共记共享</div>
              <div class="text">
                通过共同记账、共享信息，解决信息不对称引发的道德风险。
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/supply-chain/advantage-3.png"
              />
              <div class="title">智能合约自动执行</div>
              <div class="text">
                基于智能合约的自动清算能减少人工干预，降低操作风险，保障回款安全。
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/supply-chain/advantage-4.png"
              />
              <div class="title">强大的技术保障</div>
              <div class="text">
                利用区块链的同步记账、共识机制、加密算法、智能合约等技术确保了链上数字资产与交易往来的真实、准确、唯一、可追溯和不可篡改，避免出现双花问题。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 应用场景 -->
    <div class="setting">
      <div class="commom-title">应用场景</div>
      <div class="setting-content">
        <div class="list-tab">
          <div
            class="tabs"
            v-for="(item, index) in settingList"
            :key="index"
            :class="{ 'active-tab': index === tabIndex }"
            @click="tabIndex = index"
          >
            {{ item.tab }}
          </div>
        </div>
        <div class="list-detail">
          <div class="left">
            <div class="title">{{ settingList[tabIndex].title }}</div>
            <div class="text">
              {{ settingList[tabIndex].text }}
            </div>
          </div>
          <img
            class="right"
            :src="settingList[tabIndex].url"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settingList: [
        {
          tab: "供应链物流",
          title:'供应链物流',
          text:'基于区块链和物联网技术，构建由生产商、仓储、物流商和客户组成的协作联盟，将货物全流程信息可信记录，解决了信息孤岛，信息流转不畅，信息缺乏透明度等行业问题。',
          url:'https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/setting-1.png'
        },
        {
          tab: "数字仓单",
          title:'数字仓单',
          text:'通过区块链确保大宗商品在交易、仓储、物流等环节的数据真实性和货物安全性，避免虚假贸易、商业欺诈、篡改数据等交易行为风险，解决行业长期存在融资难、融资贵等痛点。',
          url:'https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/setting-2.png'
        },
        {
          tab: "应收帐款流转",
          title:'应收帐款流转',
          text:'把核心企业应付账款债权进行多级流转、拆分，为大量原本无法融资的中小企业提供了融资机会，降低了中小企业的资金成本，解决了三角债困局。',
          url:'https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/setting-3.png'
        },
        {
          tab: "票据流转",
          title:'票据流转',
          text:'通过加密算法保证数据库内的数据真实并且不可篡改，充分利用区块链的技术优势防控票据业务风险。',
          url:'https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/setting-4.png'
        },
        {
          tab: "债权确认",
          title:'债权确认',
          text:'使用区块链技术跟踪债权生命周期，确保交易的真实性和可靠性，规范交易流程，记录债权的产生和流转。',
          url:'https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/setting-5.png'
        },
        {
          tab: "供应链融资",
          title:'供应链融资',
          text:'通过区块链技术去中心化的特性，构建联盟链、使得企业、金融机构之间可以达成互信共识，促进供应链金融的发展。',
          url:'https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/setting-6.png'
        },
      ],
      tabIndex: 0,
    };
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/supply-chain/bg.png") center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0, 0, 0, 0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 388px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        text-align: center;
        .img {
          width: 124px;
          height: 124px;
          margin-top: 40px;
        }
        .title {
          margin: 28px 0px 24px;
          font-size: 19px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 26px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
          margin: 0px 16px;
        }
      }
    }
  }
  .setting {
    .setting-content {
      @extend .content-width;
      padding-bottom: 70px;
      .list-tab {
        height: 43px;
        border-bottom: 1px solid #e8eef9;
        display: flex;
        .tabs {
          flex: 1;
          height: 43px;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 26px;
          text-align: center;
          cursor: pointer;
          &:hover{
          font-family: "PingFangSC-Medium";
          color: #000000;
          transition: border-bottom 0.3s;

          }
        }
        .active-tab {
          font-family: "PingFangSC-Medium";
          color: #000000;
          border-bottom: 4px solid #0077ff;
          transition: border-bottom 0.3s;
        }
      }
      .list-detail {
        margin-top: 50px;
        height: 260px;
        background: #ffffff;
        box-shadow: 0px 0px 24px 0px rgba(18, 86, 188, 0.08);
        display: flex;
        .right {
          width: 460px;
          height: 260px;
        }
        .left {
          flex: 1;
          padding: 44px 60px 0px;
          .title {
            height: 26px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: #000000;
            line-height: 26px;
            margin-bottom: 24px;
          }
          .text {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 32px;
          }
        }
      }
    }
  }
}
</style>
